.sectionContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background: linear-gradient(95deg, #F7F9FC 0%, rgba(255, 255, 255, 0.00) 100%),
    linear-gradient(to bottom right, #C4C1F6 -10%, #DCE5F8 120%),
    linear-gradient(to top right, #F7F9FC 10%, #ECE5FF 60%),
    linear-gradient(to right, #C4C1F6 0%, #ECE5FF 100%);
}

@media (min-width: 480px) {
    .sectionContainer {
        height: 100vh;
    }
}

.sectionContainer::after {
    background: url('../../../../assets/home-background-image.png') lightgray 50% / cover no-repeat;
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    mix-blend-mode: color-burn;
}
