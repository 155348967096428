
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Plus Jakarta Sans', sans-serif !important;}

img {
    max-width: 100%;
    height: auto;
}

.App {
    min-height: 100vh;
    max-width: 1900px;
    margin: 0rem auto;
}
